import { v4 } from "uuid";
import { create } from "zustand";
import { combine } from "zustand/middleware";

import type { Toast } from "@/app/core/display/toast/toast.types";

export const useToast = create(
  combine(
    {
      toasts: [] as Toast[],
    },
    (set) => ({
      hideToast: (id: string) => {
        return set((x) => ({ toasts: x.toasts.filter((t) => t.id !== id) }));
      },

      showToast: (toast: Omit<Toast, "id">) => {
        const id = v4();

        set((x) => {
          const cleanToasts = x.toasts.filter((t) => t.message !== toast.message);

          return { toasts: [...cleanToasts, { ...toast, id }] };
        });

        return id;
      },

      updateToast: (
        id: string,
        toast: Omit<Toast, "id"> & { delay?: number | true; onUpdated?: () => void }
      ) => {
        const { delay, onUpdated, ...cleanToast } = toast;

        const setFn = (x: { toasts: Toast[] }) => {
          const targetToast = x.toasts.find((t) => t.id === id);
          if (targetToast) {
            const cleanToasts = x.toasts.filter((t) => t.id !== id);
            return { toasts: [...cleanToasts, { ...targetToast, ...cleanToast }] };
          }

          return x;
        };

        if (delay !== undefined) {
          setTimeout(
            () => {
              set(setFn);
              onUpdated?.();
            },
            typeof delay === "number" ? delay : 1500
          );
        } else {
          set(setFn);
          onUpdated?.();
        }
      },
    })
  )
);
