import React from "react";

import tw from "twin.macro";

import { styled } from "@/app/core/styles/stitches.config";
import { Box } from "@/app/core/ui/components/Box";
import { Loader } from "@/app/core/ui/components/Loader";
import { Text } from "@/app/core/ui/components/Text";

const ButtonComponent = styled("button", {
  ...tw`font-bold relative transition-colors`,

  variants: {
    size: {
      base: tw`rounded-lg text-md`,
      lg: tw`py-1.25 px-2 rounded-lg`,
      md: tw`text-[1.2rem] rounded-lg py-0.75 px-1.5`,
      none: {},
    },

    theme: {
      accent: tw`bg-accent text-white hover:bg-accent-light disabled:(opacity-50 cursor-not-allowed)`,
      "accent-faded": tw`bg-accent-faded text-white hover:opacity-90 disabled:(opacity-50 cursor-not-allowed)`,
      error: tw`bg-red-600 text-white hover:(opacity-90) disabled:(opacity-50 cursor-not-allowed)`,
      "light-gray": tw`bg-gray-100 border border-gray-200 text-gray-800 hover:bg-gray-150 disabled:(text-gray-600 hover:bg-gray-100 cursor-not-allowed)`,
      gray: tw`bg-gray-200 text-gray-900 hover:bg-gray-200`,
      white: tw`bg-white border border-gray-200 hover:(border-gray-300 bg-gray-100) disabled:(bg-gray-100 text-gray-700)`,
    },

    icon: {
      true: {},
      false: {},
    },

    spring: {
      true: tw`transition-all active:scale-[0.97]`,
      false: {},
    },
  },

  defaultVariants: {
    size: "base",
    icon: false,
    spring: false,
  },

  compoundVariants: [
    {
      icon: true,
      size: "base",
      css: {
        ...tw`py-1 pl-1.5 pr-1.75`,
      },
    },
    {
      icon: false,
      size: "base",
      css: {
        ...tw`py-1 px-1.75`,
      },
    },
  ],
});

interface ButtonProps extends Omit<React.ComponentProps<typeof ButtonComponent>, "icon"> {
  isLoading?: boolean;
  icon?: JSX.Element;
  justifyContent?: "center" | "left";
  widerGap?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  isLoading = false,
  icon,
  children,
  justifyContent = "center",
  widerGap = false,
  ...props
}) => {
  return (
    <ButtonComponent icon={Boolean(icon)} {...props}>
      <Box
        as="span"
        tw="opacity-0 transition-opacity duration-300"
        css={isLoading ? tw`opacity-100` : {}}
      >
        <Loader as="span" absoluteCentered active={isLoading} />
      </Box>

      <Box
        as="span"
        tw="w-full opacity-100 transition-opacity duration-300 flex items-center"
        css={{
          ...(isLoading ? tw`opacity-0` : {}),
          ...(justifyContent === "center" ? tw`justify-center` : {}),
          ...(widerGap ? tw`gap-1.25` : tw`gap-0.75`),
        }}
      >
        {icon ? icon : null}
        <Text as="span" nowrap>
          {children}
        </Text>
      </Box>
    </ButtonComponent>
  );
};
