import { useQuery } from "@tanstack/react-query";

import type { UseApiQuery } from "@/app/core/http/api-client.types";
import { useApiClient } from "@/app/core/http/hooks/useApiClient";

export const useApiQuery: UseApiQuery = (key, options?) => {
  const { query } = useApiClient();

  return useQuery(
    typeof key === "string" ? [key] : key,
    () => {
      const fn = query[typeof key === "string" ? key : key[0]] as any;
      return fn((options as any)?.request);
    },
    options as any
  );
};
