import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Router } from "next/router";
import nProgress from "nprogress";
import { SkeletonTheme } from "react-loading-skeleton";
import { theme } from "twin.macro";

import { WithAccessControl } from "@/app/access-control/components/WithAccessControl";
import { AuthProvider } from "@/app/auth/context/AuthProvider";
import { ToastController } from "@/app/core/display/toast/components/ToastController";
import { queryClient } from "@/app/core/http/queryClient";
import { initStyles } from "@/app/core/styles/global-styles";
import type { AppPropsWithLayout } from "@/app/core/types/tsx";
import { DeployRefreshModal } from "@/app/core/update/DeployRefreshModal";
import { GeolocationProvider } from "@/app/geo/context/GeolocationProvider";

import "@/app/core/styles/nprogress.css";
import "react-loading-skeleton/dist/skeleton.css";
import "simplebar-react/dist/simplebar.min.css";

nProgress.configure({
  showSpinner: false,
});

Router.events.on("routeChangeStart", () => nProgress.start());
Router.events.on("routeChangeComplete", () => nProgress.done());
Router.events.on("routeChangeError", () => nProgress.done());

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  initStyles();

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      <SkeletonTheme
        baseColor={theme("colors.gray.200")}
        highlightColor={theme("colors.gray.150")}
      >
        <AuthProvider skipAuth={Boolean(Component.skipAuth)}>
          <WithAccessControl role={Component.accessControl}>
            <GeolocationProvider skip={Boolean(Component.skipAuth)}>
              <Component {...pageProps} />
            </GeolocationProvider>
            <DeployRefreshModal />
          </WithAccessControl>
          <ToastController />
        </AuthProvider>
      </SkeletonTheme>
    </QueryClientProvider>
  );
}
