import { useAuth } from "@/app/auth/hooks/useAuth";
import type { UseApiQueryOptions } from "@/app/core/http/api-client.types";
import { useApiQuery } from "@/app/core/http/hooks/useApiQuery";

export const useUser = (options?: UseApiQueryOptions<"getCurrentUser">) => {
  const { isAuthed } = useAuth();

  return useApiQuery("getCurrentUser", {
    enabled: isAuthed,
    ...options,
  });
};
