import { config } from "@/app/core/utils/config";

export const routes = {
  home: "/",

  login: (next?: string) => "/login" + (!next ? "" : `?next=${encodeURIComponent(next)}`),

  notFound: "/404",

  admin: {
    users: {
      index: `/admin/users`,
      edit: (id: number) => `/admin/users/${id}/edit`,
    },
    leads: {
      index: (id: string) => `/admin/leads?item=${id}`,
    },
  },

  leads: {
    index: "/leads",
    import: "/leads/import",
    create: "/leads/new",
    partnerCheck: "/leads/check",
    id: (id: number) => `/leads/${id}`,
  },

  partners: {
    index: "/partners",
    id: (id: number, tab?: string) => `/partners/${id}${tab ? `/${tab}` : ""}`,
  },

  commercials: {
    index: "/commercials",
    id: (id: number) => `/commercials/${id}`,
  },

  campaigns: {
    index: "/campaigns",
    new: "/campaigns/new",
    id: (id: number, tab?: string) => `/campaigns/${id}${tab ? `/${tab}` : ""}`,
  },

  offers: {
    index: "/offers",
    new: "/offers/new",
  },

  me: {
    index: (tab?: string) => `/me${tab ? `?tab=${tab}` : ""}`,
  },

  public: {
    leads: {
      importTemplate: "/leads/leads-import-template.xlsm",
    },
  },

  api: {
    session: `${config.apiBaseUrl}/auth/session`,
    logIn: (next?: string) =>
      `${config.apiBaseUrl}/auth/login${next ? `?next=${encodeURIComponent(next)}` : ""}`,
    userProfilePicture: (userId: number) =>
      `${config.apiBaseUrl}/users/${userId}/picture`,
    userImpersonate: (userId: number) =>
      `${config.apiBaseUrl}/users/${userId}/impersonate`,
  },

  //
  // External
  //

  external: {
    googleMaps: {
      coordinates: (
        coords: Pick<GeolocationCoordinates, "latitude" | "longitude"> | string
      ) =>
        `https://www.google.com/maps/place/${encodeURIComponent(
          typeof coords === "string"
            ? coords
            : [coords.latitude, coords.longitude].join(",")
        )}`,
    },
  },
};
