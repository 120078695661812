"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.routeManifest = void 0;
exports.routeManifest = {
    createSession: { path: "/auth/session", method: "get" },
    logIn: { path: "/auth/login", method: "get" },
    handleRedirect: { path: "/auth/redirect", method: "get" },
    verifyTokens: { path: "/auth/tokens", method: "post" },
    logout: { path: "/auth/logout", method: "post" },
    refresh: { path: "/auth/refresh", method: "post" },
    handleOutlookNotification: { path: "/external/microsoft/outlook/:userId(int)", method: "post" },
    handleMicrosoftLifecycleNotification: { path: "/external/microsoft/lifecycle", method: "post" },
    handleDocusignConnectNotification: { path: "/external/docusign", method: "post" },
    getAlgoliaFeed: { path: "/external/algolia", method: "get" },
    getProvinces: { path: "/geo/provinces", method: "get" },
    getRegions: { path: "/geo/regions", method: "get" },
    getCities: { path: "/geo/cities", method: "get" },
    healthcheck: { path: "/healthcheck", method: "get" },
    getQueues: { path: "/queues", method: "get" },
    getQueueJobs: { path: "/queues/:queueId/jobs", method: "get" },
    retryQueueJob: { path: "/queues/:queueId/jobs/:jobId/retry", method: "post" },
    discardQueueJob: { path: "/queues/:queueId/jobs/:jobId/discard", method: "post" },
    rerunQueueJob: { path: "/queues/:queueId/jobs/:jobId", method: "post" },
    promoteQueueJob: { path: "/queues/:queueId/jobs/:jobId/promote", method: "post" },
    removeQueueJob: { path: "/queues/:queueId/jobs/:jobId", method: "delete" },
    getSchedules: { path: "/schedules", method: "get" },
    triggerSchedule: { path: "/schedules/:scheduleId", method: "post" },
    getUsers: { path: "/users", method: "get" },
    getUserById: { path: "/users/:userId(int)", method: "get" },
    deactivateUser: { path: "/users/:userId(int)", method: "delete" },
    getUserProfilePicture: { path: "/users/:userId(int)/picture", method: "get" },
    impersonateUser: { path: "/users/:userId(int)/impersonate", method: "post" },
    getProductBySku: { path: "/products/sku/:sku", method: "get" },
    getCommercials: { path: "/commercials", method: "get" },
    getCommercialFilters: { path: "/commercials/filters", method: "get" },
    getCommercialById: { path: "/commercials/:commercialId(int)", method: "get" },
    getCommercialStats: { path: "/commercials/:commercialId(int)/stats", method: "get" },
    getCommercialPartners: { path: "/commercials/:commercialId(int)/partners", method: "get" },
    getCommercialLeads: { path: "/commercials/:commercialId(int)/leads", method: "get" },
    getCommercialLeadStats: { path: "/commercials/:commercialId(int)/leads/stats", method: "get" },
    getCommercialSalesTargets: { path: "/commercials/:commercialId(int)/sales-targets", method: "get" },
    getCommercialOffers: { path: "/commercials/:commercialId(int)/offers", method: "get" },
    getCommercialOrders: { path: "/commercials/:commercialId(int)/orders", method: "get" },
    getCommercialActivities: { path: "/commercials/:commercialId(int)/activities", method: "get" },
    getCommercialActivityStats: { path: "/commercials/:commercialId(int)/activities/stats", method: "get" },
    getCommercialNames: { path: "/commercials/names", method: "get" },
    getLeads: { path: "/leads", method: "get" },
    getLeadFilters: { path: "/leads/filters", method: "get" },
    getLeadById: { path: "/leads/:leadId(int)", method: "get" },
    updateLead: { path: "/leads/:leadId(int)", method: "patch" },
    createLead: { path: "/leads", method: "post" },
    getLeadPersonas: { path: "/leads/personas", method: "get" },
    getLeadStatuses: { path: "/leads/statuses", method: "get" },
    getLeadAssignableCommercials: { path: "/leads/:leadId(int)/assignables", method: "get" },
    getLeadCnaes: { path: "/leads/cnae", method: "get" },
    getLeadOwners: { path: "/leads/owners", method: "get" },
    sendLeadSignupDocument: { path: "/leads/:leadId(int)/signup", method: "post" },
    closeOffer: { path: "/offers/:offerId(int)", method: "delete" },
    getCreatedOffers: { path: "/offers", method: "get" },
    createOfferPreview: { path: "/offers/preview", method: "post" },
    createOffer: { path: "/offers", method: "post" },
    getOfferDocument: { path: "/offers/:offerId(int)/download", method: "post" },
    getPartners: { path: "/partners", method: "get" },
    getPartnerFilters: { path: "/partners/filters", method: "get" },
    getPartnerById: { path: "/partners/:partnerId(int)", method: "get" },
    getPartnerSales: { path: "/partners/:partnerId(int)/sales", method: "get" },
    getDocumentFilters: { path: "/partners/documents/filters", method: "get" },
    getPartnerOffers: { path: "/partners/:partnerId(int)/offers", method: "get" },
    getPartnerOrders: { path: "/partners/:partnerId(int)/orders", method: "get" },
    getPartnerInvoices: { path: "/partners/:partnerId(int)/invoices", method: "get" },
    getPartnerByVatNumber: { path: "/partners/check/:vatNumber", method: "get" },
    getPartnerAddresses: { path: "/partners/:partnerId(int)/addresses", method: "get" },
    getTableViews: { path: "/table/views", method: "get" },
    createTableView: { path: "/table/view", method: "post" },
    updateTableView: { path: "/table/view/:viewId(int)", method: "patch" },
    deleteTableView: { path: "/table/view/:viewId(int)", method: "delete" },
    getCurrentUser: { path: "/me", method: "get" },
    getConfig: { path: "/me/config", method: "get" },
    getDashboard: { path: "/me/dashboard", method: "get" },
    getCurrentEntity: { path: "/me/entity", method: "get" },
    getRoles: { path: "/roles", method: "get" },
    createCommercialCampaign: { path: "/commercials/campaigns", method: "post" },
    getCommercialCampaigns: { path: "/commercials/campaigns", method: "get" },
    getCommercialCampaignFilters: { path: "/commercials/campaigns/filters", method: "get" },
    getCommercialCampaign: { path: "/commercials/campaigns/:campaignId(int)", method: "get" },
    getCommercialCampaignActivities: { path: "/commercials/campaigns/:campaignId(int)/activities", method: "get" },
    getCommercialCampaignCommercialPerformance: { path: "/commercials/campaigns/:campaignId(int)/commercials", method: "get" },
    getLeadActivityFeed: { path: "/leads/:leadId(int)/activities", method: "get" },
    getPendingLeadActivities: { path: "/leads/:leadId(int)/activities/pending", method: "get" },
    createLeadActivity: { path: "/leads/:leadId(int)/activities", method: "post" },
    updateLeadActivity: { path: "/leads/:leadId(int)/activities/:leadActivityId(int)", method: "patch" },
    uploadLeadsImportFile: { path: "/leads/import", method: "post" },
    getLeadImports: { path: "/leads/import", method: "get" },
    getLeadSources: { path: "/leads/sources", method: "get" },
    createLeadSource: { path: "/leads/sources", method: "post" },
    deleteLeadSource: { path: "/leads/sources/:sourceId(int)", method: "delete" },
    getLeadSteps: { path: "/leads/steps", method: "get" },
    getPartnerActivityFeed: { path: "/partners/:partnerId(int)/activities", method: "get" },
    createPartnerActivity: { path: "/partners/:partnerId(int)/activities", method: "post" },
    getPartnerActivityFilters: { path: "/partners/activities/filters", method: "get" },
};
