import React from "react";

import type { FCWithChildren } from "@/app/core/types/tsx";
import { Box } from "@/app/core/ui/components/Box";
import { Loader } from "@/app/core/ui/components/Loader";

interface WaitForAuthProps extends FCWithChildren {
  skipAuth: boolean;
  isAuthed: boolean;
}

export const WaitForAuth: React.FC<WaitForAuthProps> = ({
  skipAuth,
  isAuthed,
  children,
}) => {
  if (!skipAuth && !isAuthed) {
    return (
      <Box tw="w-screen h-screen fixed bg-white z-10">
        <Loader absoluteCentered />
      </Box>
    );
  }

  return <>{children}</>;
};
