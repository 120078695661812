import React from "react";

import { Slash } from "lucide-react";
import Link from "next/link";

import { useHasRole } from "@/app/access-control/hooks/useHasRole";
import { useAuth } from "@/app/auth/hooks/useAuth";
import { routes } from "@/app/core/http/routes";
import type { FCWithChildren } from "@/app/core/types/tsx";
import { Flex } from "@/app/core/ui/components/Flex";
import { Loader } from "@/app/core/ui/components/Loader";
import { Text } from "@/app/core/ui/components/Text";
import { useUser } from "@/app/user/hooks/useUser";
import type { RolePermissions } from "@obd/common";

interface WithAccessControlProps extends FCWithChildren {
  role: RolePermissions | RolePermissions[] | undefined;
}

export const WithAccessControl: React.FC<WithAccessControlProps> = ({
  role,
  children,
}) => {
  const { isLoading } = useUser();
  const hasRoles = useHasRole(role);
  const { isAuthed } = useAuth();

  if (isLoading && isAuthed) {
    return <Loader tw="bg-white h-full w-full" centered />;
  }

  if (role && !hasRoles) {
    return (
      <Flex center tw="w-full h-full flex-col bg-white">
        <Slash size="70" tw="mb-3 text-gray-500" />

        <Text weight="bolder" size="3xl" tw="mb-0.5">
          Alto ahí
        </Text>
        <Text color="gray-700">
          No tienes permisos suficientes para ver este contenido.
        </Text>

        <Link href={routes.home} tw="mt-2 underline">
          Volver
        </Link>
      </Flex>
    );
  }

  return <>{children}</>;
};
