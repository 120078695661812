import React from "react";

import type { FCWithChildren } from "@/app/core/types/tsx";
import { Box } from "@/app/core/ui/components/Box";
import { Loader } from "@/app/core/ui/components/Loader";

interface WaitForGeolocationProps extends FCWithChildren {
  hasAllowedGeo: boolean;
  triggerGeo: () => void;
  isLoading: boolean;
}

export const WaitForGeolocation: React.FC<WaitForGeolocationProps> = ({
  hasAllowedGeo,
  triggerGeo,
  isLoading,
  children,
}) => {
  // const user = useUser();

  // if (hasAllowedGeo) {
  //   return <>{children}</>;
  // }

  if (isLoading) {
    return (
      <Box tw="w-screen h-screen fixed bg-white z-10">
        <Loader absoluteCentered />
      </Box>
    );
  }

  return <>{children}</>;
};
