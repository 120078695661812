import React from "react";

import type { LucideProps } from "lucide-react";
import { Loader2 } from "lucide-react";
import tw from "twin.macro";

import { styled } from "@/app/core/styles/stitches.config";
import { Box } from "@/app/core/ui/components/Box";

const LoaderContainer = styled(Box, {
  variants: {
    centered: {
      true: tw`w-full flex items-center justify-center`,
    },
    absoluteCentered: {
      true: tw`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`,
    },
  },

  defaultVariants: {
    centered: false,
    absoluteCentered: false,
  },
});

interface LoaderProps extends React.ComponentProps<typeof LoaderContainer> {
  as?: string;
  active?: boolean;
  svgProps?: LucideProps;
}

export const Loader: React.FC<LoaderProps> = ({
  active = true,
  svgProps,
  ...props
}) => {
  if (!active) {
    return null;
  }

  return (
    <LoaderContainer {...props}>
      <Loader2 css={active ? tw`animate-rotate` : {}} {...svgProps} />
    </LoaderContainer>
  );
};
