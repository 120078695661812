/**
 * App config
 */
export const config = {
  //
  // Core
  //

  domain: process.env.NEXT_PUBLIC_TUNNEL
    ? "loca.lt"
    : process.env.NEXT_PUBLIC_DOMAIN ?? "no-domain",
  apiBaseUrl: process.env.NEXT_PUBLIC_TUNNEL
    ? "https://obd-backend.loca.lt/v1"
    : process.env.NEXT_PUBLIC_API_BASEURL ?? "no-api-baseurl",
  filtersVersion: 0,
  oblidoUserId: Number(process.env.NEXT_PUBLIC_OBLIDO_USER_ID ?? -1),

  //
  // Cookies
  //

  cookies: {
    prefix: process.env.NEXT_PUBLIC_COOKIE_PREFIX ?? "no-cookie-prefix",
  },
};
