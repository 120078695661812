import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";
import { destroyCookie, parseCookies, setCookie } from "nookies";

import type { FCWithChildren } from "@/app/core/types/tsx";
import { config } from "@/app/core/utils/config";
import { getCookieName } from "@/app/core/utils/cookies";
import { WaitForGeolocation } from "@/app/geo/components/WaitForGeolocation";

export const GeolocationContext = React.createContext<{
  updateCurrentLocation: () => void;
  isGeolocationLoading: boolean;
  isGeolocationAvailable: boolean;
  isGeolocationEnabled: boolean;
  coords?: Pick<GeolocationCoordinates, "latitude" | "longitude">;
}>({
  updateCurrentLocation: () => {},
  isGeolocationAvailable: false,
  isGeolocationEnabled: false,
  isGeolocationLoading: false,
});

interface GeolocationProviderProps extends FCWithChildren {
  skip: boolean;
}

export const GeolocationProvider: React.FC<GeolocationProviderProps> = ({
  children,
  skip,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isInsecure, setIsInsecure] = useState(false);
  const [isUnsupported, setIsUnsupported] = useState(false);
  const [geolocation, setGeolocation] =
    useState<Pick<GeolocationCoordinates, "latitude" | "longitude">>();

  const cookies = parseCookies();

  /**
   * Check if we're in an insecure (http) environment
   * and skip geo entirely
   */
  useEffect(() => {
    setIsLoading(true);
    setIsInsecure(location.protocol === "http:");
    setIsUnsupported(!("geolocation" in navigator) || !navigator.geolocation);
    setIsLoading(false);
  }, []);

  /**
   * Get the current location from the user
   * using the browser Geolocation API
   */
  const updateCurrentLocation = useCallback(() => {
    if (isLoading || isInsecure || isUnsupported || skip) {
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setGeolocation({
          longitude: pos.coords.longitude,
          latitude: pos.coords.latitude,
        });
      },
      () => {
        setGeolocation(undefined);
      }
    );
  }, [isInsecure, isLoading, isUnsupported, skip]);

  /**
   * Ensure the user has allowed location usage
   */
  useEffect(() => {
    if (!isInsecure) {
      updateCurrentLocation();
    }
  }, [updateCurrentLocation, isInsecure]);

  /**
   * Set a cookie for the server to grab the geo
   * info from a user
   */
  useEffect(() => {
    if (isInsecure) {
      return;
    }

    const cookie = getCookieName("geo");

    if (cookies[cookie] && !geolocation) {
      destroyCookie(null, cookie, {
        domain: config.domain,
        path: "/",
      });
    } else if (geolocation) {
      setCookie(null, cookie, String(geolocation), {
        domain: config.domain,
        path: "/",
        expires: DateTime.now().plus({ hour: 1 }).toJSDate(),
      });
    }
  }, [cookies, geolocation, isInsecure]);

  return (
    <GeolocationContext.Provider
      value={useMemo(
        () => ({
          updateCurrentLocation,
          isGeolocationAvailable: !isInsecure && !isUnsupported,
          isGeolocationEnabled: Boolean(geolocation),
          isGeolocationLoading: isLoading,
          coords: geolocation,
        }),
        [geolocation, isInsecure, isLoading, isUnsupported, updateCurrentLocation]
      )}
    >
      {isInsecure || skip ? (
        children
      ) : (
        <WaitForGeolocation
          hasAllowedGeo={Boolean(geolocation)}
          triggerGeo={updateCurrentLocation}
          isLoading={isLoading}
        >
          {children}
        </WaitForGeolocation>
      )}
    </GeolocationContext.Provider>
  );
};
