import React, { useEffect, useMemo, useRef } from "react";

import { AlertCircle, Check, Info, X } from "lucide-react";
import tw from "twin.macro";

import type { Toast as ToastType } from "@/app/core/display/toast/toast.types";
import { Box } from "@/app/core/ui/components/Box";
import { Flex } from "@/app/core/ui/components/Flex";
import { Loader } from "@/app/core/ui/components/Loader";
import { Text } from "@/app/core/ui/components/Text";

interface ToastProps extends Omit<ToastType, "id"> {
  onClose?: () => void;
}

export const Toast: React.FC<ToastProps> = ({
  message,
  action,
  type = "info",
  behavior = "ephemeral",
  duration = 7000,
  onClose,
}) => {
  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;

  useEffect(() => {
    if (behavior === "sticky") {
      return;
    }

    const id = setTimeout(() => {
      onCloseRef.current?.();
    }, duration);

    return () => clearTimeout(id);
  }, [behavior, duration]);

  const toastUi = useMemo(() => {
    switch (type) {
      case "error":
        return {
          icon: <AlertCircle size="22" tw="text-red-600" />,
          indicator: tw`bg-red-600`,
        };
      case "loading":
        return {
          icon: <Loader tw="text-gray-600" />,
          indicator: tw`bg-gray-600`,
        };
      case "success":
        return {
          icon: <Check size="22" tw="text-success" />,
          indicator: tw`bg-success`,
        };
      case "info":
      default:
        return {
          icon: <Info size="22" tw="text-accent" />,
          indicator: tw`bg-accent`,
        };
    }
  }, [type]);

  return (
    <Flex
      tw="justify-between rounded-lg overflow-hidden min-w-[350px] max-w-[500px]
        bg-gray-900 shadow-lg text-white"
    >
      <Box tw="w-0.75 flex-shrink-0" css={toastUi.indicator} />

      <Flex tw="gap-1.5 px-1.5 flex-grow py-1.5 items-center">
        {toastUi.icon}

        <Text tw="break-words">{message}</Text>
      </Flex>

      {onClose ? (
        <Flex
          as="button"
          center
          tw="px-1 border-l border-gray-800 flex-shrink-0 hover:(bg-gray-800)"
          onClick={onClose}
        >
          <X size="15" />
        </Flex>
      ) : null}
    </Flex>
  );
};
