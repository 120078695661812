import * as Sentry from "@sentry/nextjs";
import type * as TSentry from "@sentry/types";

export type SentryBreadcrumbCategories = "auth" | "ui.action";

export type SentryContext = never;

export type SentryTag = never;

/**
 * Add breadcrumbs
 * @see https://docs.sentry.io/platforms/javascript/guides/nextjs/enriching-events/breadcrumbs/
 */
const breadcrumb = (
  level: TSentry.SeverityLevel,
  category: SentryBreadcrumbCategories,
  message: string
) =>
  Sentry.addBreadcrumb({
    level,
    category,
    message,
  });

/**
 * Add context to attach arbitrary data to an event.
 * @see https://docs.sentry.io/platforms/javascript/guides/nextjs/enriching-events/context/
 */
const context = (name: SentryContext, data: { [key: string]: any } | null) =>
  Sentry.setContext(name, data);

/**
 * Add tags to an event.
 * @see https://docs.sentry.io/platforms/javascript/guides/nextjs/enriching-events/tags/
 */
function tag(name: SentryTag, value: TSentry.Primitive): void;
function tag(tags: Record<SentryTag, TSentry.Primitive>): void;
function tag(
  tagsOrName: Record<SentryTag, TSentry.Primitive> | SentryTag,
  value?: TSentry.Primitive
): void {
  return typeof tagsOrName === "string"
    ? Sentry.setTag(tagsOrName, value)
    : Sentry.setTags(tagsOrName);
}

/**
 * Wrapper for common Sentry funcions with additional
 * processing and typesafety
 */
export const sentry = { breadcrumb, context, tag, user: Sentry.setUser };
