"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyPathParams = void 0;
var applyPathParams = function (url, params) {
    var newUrl = url;
    Object.keys(params).forEach(function (param) {
        var regex = new RegExp("(:".concat(param, "(\\([a-z]+\\))?)"));
        newUrl = newUrl.replace(regex, params[param]);
    });
    return newUrl;
};
exports.applyPathParams = applyPathParams;
