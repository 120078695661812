import { styled } from "@stitches/react";
import tw from "twin.macro";

import { Box } from "@/app/core/ui/components/Box";

export const Text = styled(Box, {
  variants: {
    size: {
      xs: tw`text-xs`,
      sm: tw`text-sm`,
      md: tw`text-md`,
      base: tw`text-base`,
      lg: tw`text-lg`,
      xl: tw`text-xl`,
      "2xl": tw`text-2xl`,
      "3xl": tw`text-3xl`,
    },

    color: {
      black: tw`text-black`,
      white: tw`text-white`,
      accent: tw`text-accent`,
      "accent-faded": tw`text-accent-faded`,
      success: tw`text-success`,

      "red-600": tw`text-red-600`,
      "red-800": tw`text-red-800`,

      "orange-600": tw`text-orange-600`,

      "gray-100": tw`text-gray-100`,
      "gray-300": tw`text-gray-300`,
      "gray-500": tw`text-gray-500`,
      "gray-600": tw`text-gray-600`,
      "gray-700": tw`text-gray-700`,
      "gray-800": tw`text-gray-800`,
      "gray-900": tw`text-gray-900`,
    },

    weight: {
      normal: tw`font-normal`,
      medium: tw`font-medium`,
      bold: tw`font-bold`,
      bolder: tw`font-bolder`,
      extrabold: tw`font-extrabold`,
    },

    align: {
      center: tw`text-center`,
      right: tw`text-right`,
    },

    nowrap: {
      true: tw`whitespace-nowrap`,
    },

    italic: {
      true: tw`italic`,
    },
  },

  defaultVariants: {
    nowrap: false,
  },
});
