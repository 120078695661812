import React from "react";

import { AnimatePresence, motion } from "framer-motion";

import { Toast } from "@/app/core/display/toast/components/Toast";
import { useToast } from "@/app/core/display/toast/store/useToast";
import { Flex } from "@/app/core/ui/components/Flex";

interface ToastControllerProps {}

export const ToastController: React.FC<ToastControllerProps> = () => {
  const { toasts, hideToast } = useToast();

  return (
    <Flex tw="fixed bottom-3 items-center flex-col gap-1 left-1/2 transform -translate-x-1/2 z-[100]">
      <AnimatePresence>
        {toasts.map((t) => (
          <motion.div
            key={t.id}
            initial={{ opacity: 0, y: 20, scale: 0.7 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 20, scale: 0 }}
          >
            <Toast onClose={() => hideToast(t.id)} {...t} />
          </motion.div>
        ))}
      </AnimatePresence>
    </Flex>
  );
};
