import { globalStyles, theme } from "twin.macro";

import { globalCss } from "@/app/core/styles/stitches.config";

const customStyles = {
  ":root": {
    "--color-black": "#0D0F0F",
    "--color-white": "#FFF",

    "--color-accent": "#1A96FC",
    "--color-accent-light": "#3D9FF4",
    "--color-accent-faded": "#0091AE",

    "--color-gray-50": "#FBFCFD",
    "--color-gray-100": "#F9FBFC",
    "--color-gray-150": "#F4F7F9",
    "--color-gray-200": "#E8ECEE",
    "--color-gray-300": "#E3E6E8",
    "--color-gray-500": "#AEB0B0",
    "--color-gray-600": "#9DA1A4",
    "--color-gray-700": "#828a8a",
    "--color-gray-800": "#464d4d",
    "--color-gray-900": "#191A1A",

    "--color-red-200": "#FFEFEF",
    "--color-red-300": "#F7D8D8",
    "--color-red-500": "#DC3D43",
    "--color-red-600": "#FF3030",
    "--color-red-800": "#CD2B31",

    "--color-orange-600": "#EEA825",

    "--color-success": "#03C350",
  },

  html: {
    "-webkit-font-smoothing": "antialiased",
    "-moz-osx-font-smoothing": "grayscale",
    "font-size": "62.5%",
    height: "100%",
    "scroll-behavior": "smooth",
  },

  "html.overflow-hidden": {
    overflow: "hidden",
  },

  "html:not(.overflow-hidden)": {
    "overflow-y": "scroll",
  },

  ".simplebar-scrollbar::before": {
    background: theme("colors.gray.500"),
    opacity: "0.4 !important",

    "&:hover": {
      opacity: "1 !important",
    },
  },

  ".simplebar-scrollbar.simplebar-hover::before": {
    opacity: "0.8 !important",
  },

  ".simplebar-track": {
    background: theme("colors.gray.100"),
    "border-left": `1px solid ${theme("colors.gray.150")}`,
    width: "12px !important",
  },

  body: {
    height: "100%",
    "background-color": theme("colors.gray.100"),
    color: theme("colors.gray.900"),
    "font-size": "1.4rem",
    "font-family":
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
  },

  "#__next": {
    height: "100%",
  },

  "body.no-scroll body": {
    "overflow-y": "scroll",
  },

  "@keyframes rotate": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },

  ".container": {
    width: "100%",
    "padding-left": "2rem",
    "padding-right": "2rem",

    "@media (min-width: 1024px)": {
      "padding-left": "3rem",
      "padding-right": "3rem",
    },

    "@media (min-width: 1280px)": {
      "padding-left": "5rem",
      "padding-right": "5rem",
    },
  },
};

export const initStyles = () => {
  globalCss(customStyles)();
  globalCss(globalStyles as any)();
};
