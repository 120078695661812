import { useMutation } from "@tanstack/react-query";

import type { UseApiMutation } from "@/app/core/http/api-client.types";
import { useApiClient } from "@/app/core/http/hooks/useApiClient";

export const useApiMutation: UseApiMutation = (key, options?) => {
  const { mutation } = useApiClient();

  return useMutation((params) => (mutation[key] as any)(params), options) as any;
};
