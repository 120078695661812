import React, { useEffect, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { Rocket } from "lucide-react";
import { useRouter } from "next/router";
import { createPortal } from "react-dom";

import { Button } from "@/app/core/ui/components/Button";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";
import { useInterval } from "@/app/core/utils/hooks/useInterval";

interface DeployRefreshModalProps {}

export const DeployRefreshModal: React.FC<DeployRefreshModalProps> = () => {
  const [currentBuildId, setCurrentBuildId] = useState<string>();
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

  useInterval(
    async () => {
      if (isNewVersionAvailable) {
        return;
      }

      try {
        const res = await fetch("/api/version", { method: "get" });
        const { buildId } = (await res.json()) as Partial<{ buildId: string }>;

        if (buildId && !currentBuildId) {
          setCurrentBuildId(buildId);
          return;
        }

        if (buildId && currentBuildId && buildId !== currentBuildId) {
          // Build ID changed, do something
          setIsNewVersionAvailable(true);
        }
      } catch {}
    },
    { interval: 30000, enabled: !isNewVersionAvailable }
  );

  //
  // On route change, force a reload
  //

  const { asPath } = useRouter();
  useEffect(() => {
    if (isNewVersionAvailable) {
      location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  if (!isNewVersionAvailable) {
    return null;
  }

  return (
    <>
      {createPortal(
        <AnimatePresence>
          {isNewVersionAvailable && (
            <motion.div tw="fixed right-1.5 bottom-1.5 bg-white p-2 shadow-2xl rounded-lg w-2/6 border border-gray-200 flex flex-col">
              <Flex tw="items-center gap-1.25">
                <Flex center tw="bg-[#F0F8FF] text-accent rounded-full w-3.5 h-3.5">
                  <Rocket size="17" />
                </Flex>
                <Text size="lg" weight="bold">
                  Nueva versión disponible
                </Text>
              </Flex>

              <Flex tw="flex-col gap-1 pl-[4.75rem] mt-0.25">
                <Text size="md" color="gray-600">
                  Hay una nueva versión disponible de Oblido. Por favor actualiza la
                  página para disfrutar de las últimas actualizaciones.
                </Text>
                <Button
                  theme="accent"
                  tw="self-start mt-1"
                  onClick={() => location.reload()}
                >
                  Actualizar ahora
                </Button>
              </Flex>
            </motion.div>
          )}
        </AnimatePresence>,
        document.querySelector("#__next")!
      )}
    </>
  );
};
