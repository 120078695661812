import { useMemo } from "react";

import { useUser } from "@/app/user/hooks/useUser";
import type { RolePermissions } from "@obd/common";

export const useHasRole = (
  roles: RolePermissions | RolePermissions[] | undefined
) => {
  const { data: user } = useUser();

  return useMemo(
    () =>
      (Array.isArray(roles) ? roles : [roles]).every(
        (r) => r && user?.role?.permissions.includes(r)
      ),
    [roles, user?.role?.permissions]
  );
};
