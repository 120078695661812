import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { WaitForAuth } from "@/app/auth/components/WaitForAuth";
import { useTokens } from "@/app/auth/store/useTokens";
import { useApiMutation } from "@/app/core/http/hooks/useApiMutation";
import { routes } from "@/app/core/http/routes";
import { sentry } from "@/app/core/observability/sentry";
import type { FCWithChildren } from "@/app/core/types/tsx";

export const AuthContext = React.createContext<{
  isAuthLoading: boolean;
  isAuthed: boolean;
  authenticate: (opts?: { noRedirect?: boolean }) => Promise<void>;
  logout: () => Promise<void>;
}>({
  isAuthLoading: false,
  isAuthed: false,
  authenticate: async () => {},
  logout: async () => {},
});

interface AuthProviderProps extends FCWithChildren {
  skipAuth: boolean;
}

/**
 * Main logic behind making sure each user is
 * authenticated and that they have the appropriate role
 * to access specific content.
 */
export const AuthProvider: React.FC<AuthProviderProps> = ({ skipAuth, children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const isAuthLoading = useRef(false);

  const { replace } = useRouter();

  const queryClient = useQueryClient();
  const { mutateAsync: getTokens } = useApiMutation("verifyTokens");
  const { mutateAsync: logoutUser } = useApiMutation("logout");

  const tokens = useTokens((s) => s.tokens);

  /**
   * Run the authentication flow
   */
  const authenticate = useCallback(
    async (opts?: { noRedirect?: boolean }) => {
      sentry.breadcrumb("info", "auth", "tokens.start");

      isAuthLoading.current = true;
      setIsLoading(true);

      try {
        const res = await getTokens(undefined);

        if (!res.tokens) {
          throw new Error();
        }

        useTokens.setState({
          tokens: res.tokens,
          impersonation: Boolean(res.impersonation),
        });

        queryClient.resetQueries();

        sentry.breadcrumb("info", "auth", "tokens.success");
        if (res.user) {
          sentry.user({
            id: String(res.user.id),
            username: [res.user.firstName, res.user.lastName].join(" "),
            email: res.user.email,
            role: res.user.role?.name,
          });
        }
      } catch {
        useTokens.setState({ tokens: null });
        queryClient.resetQueries();
        sentry.user(null);
        sentry.breadcrumb("info", "auth", "tokens.error");

        if (!skipAuth && !opts?.noRedirect) {
          const next = location.pathname === "/" ? undefined : location.pathname;
          replace(routes.login(next));
        }
      } finally {
        isAuthLoading.current = false;
        setIsLoading(false);
      }
    },
    [getTokens, queryClient, replace, skipAuth]
  );

  /**
   * Logout a user
   */
  const logout = useCallback(async () => {
    sentry.breadcrumb("info", "auth", "logout.attempt");

    isAuthLoading.current = true;

    try {
      await logoutUser(undefined);
    } catch {}

    sentry.breadcrumb("info", "auth", "logout.success");

    useTokens.setState({ tokens: null });
    location.reload();
  }, [logoutUser]);

  /**
   * Attempt authentication as soon as the
   * page loads
   */
  useEffect(() => {
    if (!tokens && !isAuthLoading.current) {
      authenticate();
    }
  }, [authenticate, skipAuth, tokens]);

  return (
    <AuthContext.Provider
      value={useMemo(
        () => ({
          isAuthLoading: isLoading,
          isAuthed: Boolean(tokens),
          authenticate,
          logout,
        }),
        [authenticate, isLoading, logout, tokens]
      )}
    >
      <WaitForAuth skipAuth={skipAuth} isAuthed={Boolean(tokens)}>
        {children}
      </WaitForAuth>
    </AuthContext.Provider>
  );
};
