import { QueryClient } from "@tanstack/react-query";

import { useToast } from "@/app/core/display/toast/store/useToast";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60 * 5,
      onError: (err) => {
        if (err && "message" in (err as Error)) {
          useToast
            .getState()
            .showToast({ message: (err as Error).message, type: "error" });
        }
      },
    },
    mutations: {
      onError: (err) => {
        if (err && "message" in (err as Error)) {
          useToast
            .getState()
            .showToast({ message: (err as Error).message, type: "error" });
        }
      },
    },
  },
});
