import { useEffect, useRef } from "react";

export const useInterval = <P extends (...args: any[]) => any>(
  callback: P,
  {
    interval,
    lead,
    enabled = true,
  }: { interval: number; lead?: boolean; enabled?: boolean }
) => {
  const savedCallback = useRef<P>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const tick = (): void => savedCallback.current?.();

    if (lead) {
      tick();
    }

    if (interval !== null) {
      const id = setInterval(tick, interval);
      return () => clearInterval(id);
    }
  }, [enabled, interval, lead]);
};
