import tw from "twin.macro";

import { styled } from "@/app/core/styles/stitches.config";
import { Box } from "@/app/core/ui/components/Box";

export const Flex = styled(Box, {
  ...tw`flex`,
  variants: {
    center: {
      true: tw`justify-center items-center`,
    },
  },
});
